import { useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionMode,
} from "react-flow-renderer";
import { useExecutionStateReponseHandler } from "./context/InstructionExecutionStateContextHandler";
import { useMobileViewHandler } from "./context/MobileViewContextHandler";

import CustomEdge from "./edges/CustomEdge";
import { generateEdges } from "./edges/Edges";
import { ComponentNodes, ComponentNodeTypes } from "./nodes/ComponentNodes";
import {
  IntermediateNodes,
  IntermediateNodeTypes,
} from "./nodes/intermediatenodes/IntermediateNodes";
import {
  MultiplexerNodes,
  MultiplexerNodeTypes,
} from "./nodes/MultiplexerNodes";

const Processor: React.FC = () => {
  const isMobile = useMobileViewHandler();
  const { executionStateResponseData } = useExecutionStateReponseHandler();
  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      ...ComponentNodeTypes,
      ...MultiplexerNodeTypes,
      ...IntermediateNodeTypes,
    }),
    []
  );

  const nodes = [...ComponentNodes, ...MultiplexerNodes, ...IntermediateNodes];

  const edges = generateEdges(
    executionStateResponseData.clearState,
    executionStateResponseData.instructionWireDetails
  );

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      edges={edges}
      edgeTypes={edgeTypes}
      fitView
      minZoom={isMobile ? 1.5 : 2}
      zoomOnPinch={isMobile ? true : false}
      zoomOnScroll={isMobile ? false : true}
      maxZoom={3}
      connectionMode={ConnectionMode.Loose}
    >
      <Background variant={BackgroundVariant.Lines} size={1} />
    </ReactFlow>
  );
};

export default Processor;
