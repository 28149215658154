import { Card, CardHeader, SxProps, Typography } from "@mui/material";

const cardStyle: SxProps = {
  backgroundColor: "#1F1F25",
  padding: "5px",
  borderRadius: "5px",
  height: "92%",
};

const cardHeaderStyle: SxProps = {
  color: "white",
  paddingLeft: "2px",
  paddingTop: "0px",
  paddingBottom: "2px",
};

const textCardStyle: SxProps = {
  backgroundColor: "#393939",
  padding: "5px",
  height: "65%",
};

interface AssemblerLogProps {
  message: string;
}

const AssemblerLog: React.FC<AssemblerLogProps> = ({
  message,
}: AssemblerLogProps) => {
  return (
    <Card sx={cardStyle}>
      <CardHeader
        title="Assembly logs"
        sx={cardHeaderStyle}
        titleTypographyProps={{ variant: "body1" }}
      />
      <Card sx={textCardStyle}>
        <Typography sx={{ fontSize: 14 }} color="white">
          {message}
        </Typography>
      </Card>
    </Card>
  );
};

export default AssemblerLog;
