import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const PCIncrementInputMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="1-PCIncrementInputMultiplexerNode"
      leftBottomId="0-PCIncrementInputMultiplexerNode"
      topId="top-PCIncrementInputMultiplexerNode"
      rightId="output-PCIncrementInputMultiplexerNode"
      topInput="1"
      bottomInput="0"
    />
  );
};

export default PCIncrementInputMultiplexerNode;
