import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { InstructionExecutionStateData } from "../../types/InstructionExecutionState";
import { InstructionWireData } from "../../types/InstructionWire";

export interface ExecutionStateResponse {
  executionStateResponseData: InstructionExecutionStateData;
  setExecutionStateResponseData: (
    executionStateResponseData: InstructionExecutionStateData
  ) => void;
}

interface ChildComponentsProp {
  children: React.ReactNode;
}

export const DEFAULT_INSTRUCTION_WIRE_DETAILS: InstructionWireData = {
  updatedPCValue: 0,
  machineCodeWires: {
    currentPCValue: 0,
    currentMachineCode: 0,
    conditionSegment: 0,
    opSegment: 0,
    sBitSegment: 0,
    wBitSegment: 0,
    uBitSegment: 0,
    pBitSegment: 0,
    lBitSegment: 0,
    iBitSegment: 0,
    rnRegisterSegment: 0,
    rdRegisterSegment: 0,
    rmRegisterSegment: 0,
    immediateSegment: 0,
  },
  decoderWires: {
    pcsSegment: false,
    flagWriteSegment: false,
    memoryToRegisterSegment: false,
    memoryWriteSegment: false,
    aluControlSegment: 0,
    aluSourceIndexZeroSegment: false,
    aluSourceIndexOneSegment: false,
    registerWriteSegment: false,
    registerSourceSegment: false,
    rnRegisterWriteSegment: false,
    postIndexInstructionSegment: false,
  },
  registerFileWires: {
    registerEntryTwoSegment: 0,
    registerOutputOneSegment: 0,
    registerOutputTwoSegment: 0,
  },
  aluWires: {
    sourceAALUUnitSegment: 0,
    sourceBALUUnitSegment: 0,
    aluFlagsSegment: {
      zeroFlag: false,
      carryFlag: false,
      negativeFlag: false,
      overflowFlag: false,
    },
    aluResultSegment: 0,
  },
  conditionalWires: {
    pcsrcSegment: false,
    currentFlagsSegment: {
      zeroFlag: false,
      carryFlag: false,
      negativeFlag: false,
      overflowFlag: false,
    },
  },
  dataMemoryWires: {
    dataMemoryAddressTarget: 0,
    readDataSegment: 0,
    resultSegment: 0,
  },
};

const initialisedInstructionExecutionState: InstructionExecutionStateData = {
  clearState: true,
  executionComment: "No assembly instructions executed",
  instructionWireDetails: DEFAULT_INSTRUCTION_WIRE_DETAILS,
  dataMemoryState: [],
  registerFileState: [],
};

const InstructionExecutionStateContext = createContext<ExecutionStateResponse>({
  executionStateResponseData: initialisedInstructionExecutionState,
  setExecutionStateResponseData: (
    executionStateResponseData: InstructionExecutionStateData
  ) => {},
});

const executionStateResponseDataStorageKey: string =
  "executionStateResponseData";

const getLocalStorage = (
  key: string,
  initialisedExecutionStateResponseData: InstructionExecutionStateData
): InstructionExecutionStateData => {
  const executionStateResponseData: string | null =
    window.localStorage.getItem(key);
  if (executionStateResponseData === null) {
    return initialisedExecutionStateResponseData;
  }
  return JSON.parse(executionStateResponseData);
};

const InstructionExecutionStateContextHandler: React.FC<
  ChildComponentsProp
> = ({ children }: ChildComponentsProp) => {
  const [executionStateResponseData, setExecutionStateResponseData] =
    useState<InstructionExecutionStateData>(
      getLocalStorage(
        executionStateResponseDataStorageKey,
        initialisedInstructionExecutionState
      )
    );

  useEffect(() => {
    window.localStorage.setItem(
      executionStateResponseDataStorageKey,
      JSON.stringify(executionStateResponseData)
    );
  }, [executionStateResponseData]);

  const executionResponseValue = useMemo<ExecutionStateResponse>(
    () => ({ executionStateResponseData, setExecutionStateResponseData }),
    [executionStateResponseData]
  );

  return (
    <InstructionExecutionStateContext.Provider value={executionResponseValue}>
      {children}
    </InstructionExecutionStateContext.Provider>
  );
};

const useExecutionStateReponseHandler = (): ExecutionStateResponse => {
  return useContext<ExecutionStateResponse>(InstructionExecutionStateContext);
};

export {
  InstructionExecutionStateContextHandler,
  useExecutionStateReponseHandler,
};
