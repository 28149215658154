import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-assembly_x86";
import "ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import { useAssemblyCodePostHandler } from "../context/AssemblyCodeContextHandler";

interface EditorProps {
  onTextChange: (updateCode: string) => void;
}

const Editor: React.FC<EditorProps> = ({ onTextChange }: EditorProps) => {
  const { assemblyCodeWritten } = useAssemblyCodePostHandler();
  return (
    <AceEditor
      mode="assembly_x86"
      theme="tomorrow_night_eighties"
      onChange={onTextChange}
      fontSize={16}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      setOptions={{
        tabSize: 4,
      }}
      value={assemblyCodeWritten}
      width="auto"
      height="100%"
    />
  );
};

export default Editor;
