import ALUNode from "./ALUNode";
import ClockNode from "./ClockNode";
import conditionalNodes from "./conditionalunit/ConditionalUnitNodes";
import ConditionCheckNode from "./conditionalunit/ConditionCheckNode";
import StatusNode from "./conditionalunit/StatusNode";
import DataMemoryNode from "./DataMemoryNode";
import DecoderNode from "./DecoderNode";
import InstructionMemoryNode from "./InstructionMemoryNode";
import ExecutionCommentNode from "./instructionutilityunit/ExecutionCommentNode";
import { instructionUtilityNodes } from "./instructionutilityunit/InstructionUtilityUnitNodes";
import NextInstructionNode from "./instructionutilityunit/NextInstructionNode";
import PreviousInstructionNode from "./instructionutilityunit/PreviousInstructionNode";
import PCAddFourNode from "./PCAddFourNode";
import RegisterFileNode from "./RegisterFileNode";

export const ComponentNodeTypes = {
  instructionMemoryNode: InstructionMemoryNode,
  registerFileNode: RegisterFileNode,
  dataMemoryNode: DataMemoryNode,
  decoderNode: DecoderNode,
  aluNode: ALUNode,
  pcAddFourNode: PCAddFourNode,
  clockNode: ClockNode,

  conditionCheckNode: ConditionCheckNode,
  statusNode: StatusNode,

  nextInstructionNode: NextInstructionNode,
  previousInstructionNode: PreviousInstructionNode,
  executionCommentNode: ExecutionCommentNode,
};

export const ComponentNodes = [
  ...conditionalNodes,
  ...instructionUtilityNodes,
  {
    id: "instructionMemoryNode",
    type: "instructionMemoryNode",
    position: { x: -230, y: 70 },
    data: "",
  },
  {
    id: "registerFileNode",
    type: "registerFileNode",
    position: { x: -50, y: 70 },
    data: "",
  },
  {
    id: "decoderNode",
    type: "decoderNode",
    position: { x: -110, y: -100 },
    data: "",
  },
  {
    id: "aluNode",
    type: "aluNode",
    position: { x: 120, y: 77.5 },
    data: "",
  },
  {
    id: "pcAddFourNode",
    type: "pcAddFourNode",
    position: { x: -210, y: 205 },
    data: "",
  },
  {
    id: "clockNode",
    type: "clockNode",
    position: { x: -260, y: 20 },
    data: "",
  },
  {
    id: "dataMemoryNode",
    type: "dataMemoryNode",
    position: { x: 210, y: 95 },
    data: "",
  },
];
