import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { CustomHandle } from "./CustomHandle";
import GeneralNodeNoTable from "./GeneralNodeNoTable";

const nodeHeight: number = 15;
const nodeWidth: number = 30;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const ClockNode: React.FC = () => {
  return (
    <>
      <GeneralNodeNoTable name="CLK" gridStyle={gridStyle} isRotate={false} />
      <CustomHandle
        id="input-ClockNode"
        type="target"
        position={Position.Top}
        toolTipLabel={"PC+"}
        handleStyle={{}}
      />
      <CustomHandle
        id="output-ClockNode"
        type="source"
        position={Position.Bottom}
        toolTipLabel={"PC"}
        handleStyle={{}}
      />
    </>
  );
};

export default ClockNode;
