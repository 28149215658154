import { Grid } from "@mui/material";
import { Handle, Position } from "react-flow-renderer";

const intermediateNodeStyle = {
  height: "1px",
  width: "1px",
  borderRadius: "0px",
  border: "0px",
  opacity: 0,
};

interface IntermediateNodeProps {
  id: string;
}

const IntermediateNode: React.FC<IntermediateNodeProps> = ({ id }) => {
  return (
    <>
      <Grid sx={intermediateNodeStyle}>
        <Handle
          id={id}
          type="source"
          position={Position.Top}
          style={intermediateNodeStyle}
          isConnectable={false}
        />
      </Grid>
    </>
  );
};

export default IntermediateNode;
