import { InstructionExecutionStateData } from "../types/InstructionExecutionState";

const SubmitChangeInstruction = (
  path: string,
  responseHandler: (
    executionStateReponseData: InstructionExecutionStateData
  ) => void
) => {
  fetch(path, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((res: Response) => res.json())
    .then((executionStateResponseData: InstructionExecutionStateData) => {
      responseHandler(executionStateResponseData);
    });
};

export default SubmitChangeInstruction;
