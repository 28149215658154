import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const RegisterSecondInputMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="0-RegisterSecondInputMultiplexerNode"
      leftBottomId="1-RegisterSecondInputMultiplexerNode"
      topId="top-RegisterSecondInputMultiplexerNode"
      rightId="output-RegisterSecondInputMultiplexerNode"
      topInput="0"
      bottomInput="1"
    />
  );
};

export default RegisterSecondInputMultiplexerNode;
