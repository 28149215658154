import { Grid, Typography } from "@mui/material";

const NotFound: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80%",
      }}
      direction="column"
    >
      <Grid item>
        <Typography variant="h4" display="inline">
          Page not found!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
