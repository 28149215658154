import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const TargetAddressMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="1-TargetAddressMultiplexerNode"
      leftBottomId="0-TargetAddressMultiplexerNode"
      topId="top-TargetAddressMultiplexerNode"
      rightId="output-TargetAddressMultiplexerNode"
      topInput="1"
      bottomInput="0"
    />
  );
};

export default TargetAddressMultiplexerNode;
