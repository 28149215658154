import { Button, ButtonProps, SxProps } from "@mui/material";

const buttonStyle: SxProps = {
  borderRadius: "16px",
  backgroundColor: "#75F8AA",
  fontSize: "15px",
  color: "black",
  "&:hover": {
    background: "#75F8AA",
  },
};

interface Props extends ButtonProps {
  onSubmit: () => void;
}

const SubmitButton = ({ onSubmit, ...props }: Props) => {
  return (
    <Button onClick={onSubmit} sx={buttonStyle} variant="contained" {...props}>
      Submit code
    </Button>
  );
};

export default SubmitButton;
