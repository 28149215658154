import { Box, Grid } from "@mui/material";

import Submit from "../api/Submit";
import { AssemblyCodeData } from "../types/AssemblyCode";
import { InitialStateData } from "../types/InitialState";
import SubmitButton from "./buttons/SubmitButton";
import { useAssemblyCodePostHandler } from "./context/AssemblyCodeContextHandler";
import { useInitialReponseHandler } from "./context/InitialResponseContextHandler";
import {
  DEFAULT_INSTRUCTION_WIRE_DETAILS,
  useExecutionStateReponseHandler,
} from "./context/InstructionExecutionStateContextHandler";
import Editor from "./editor/Editor";
import EditorHeader from "./editor/EditorHeader";
import AssemblerLog from "./logbox/AssemblerLog";

const CodePlatform: React.FC = () => {
  const { initialResponseData, setInitialResponseData } =
    useInitialReponseHandler();
  const { setExecutionStateResponseData } = useExecutionStateReponseHandler();
  const { assemblyCodeWritten, setAssemblyCodeWritten } =
    useAssemblyCodePostHandler();

  const onCodeChange = (newCodeValue: string) => {
    setAssemblyCodeWritten(newCodeValue);
  };

  const handleInitialStateResponse = (
    initialStateResponse: InitialStateData
  ) => {
    setInitialResponseData(initialStateResponse);
    setExecutionStateResponseData({
      clearState: true,
      executionComment: "No assembly instructions executed",
      instructionWireDetails: DEFAULT_INSTRUCTION_WIRE_DETAILS,
      dataMemoryState: initialResponseData.dataMemoryState,
      registerFileState: [],
    });
  };

  const submitCode = () => {
    let assemblyCodeData: AssemblyCodeData = { linesOfCode: [] };
    const linesOfCode: string[] = assemblyCodeWritten.split(/\r?\n/);
    linesOfCode.forEach((codeLine: string, index: number) => {
      assemblyCodeData.linesOfCode.push({
        lineNumber: index + 1,
        codeLine: codeLine,
      });
    });
    Submit(assemblyCodeData, handleInitialStateResponse);
  };

  return (
    <Grid
      container
      spacing={1}
      item
      direction="column"
      sx={{ height: "100%", padding: "10px" }}
    >
      <Grid item xs={9}>
        <Box sx={{ height: "100%" }}>
          <Box sx={{ height: "10%" }}>
            <EditorHeader />
          </Box>
          <Box sx={{ height: "90%" }}>
            <Editor onTextChange={onCodeChange} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <AssemblerLog message={initialResponseData.assemblerMessage} />
      </Grid>
      <Grid
        item
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <SubmitButton onSubmit={submitCode} />
      </Grid>
    </Grid>
  );
};

export default CodePlatform;
