import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface AssemblyCodePost {
  assemblyCodeWritten: string;
  setAssemblyCodeWritten: (assemblyCodeWritten: string) => void;
}

interface ChildComponentsProp {
  children: React.ReactNode;
}

const initialisedAssemblyCodeWritten: string = `@ start writing your assembly code
@ under the 'main' label
@ Here is a sample program to compute
@ the first 10 values of the 
@ fibonacci sequence
main: 
    LDR R0, N
    SUB R0, #2
    ADD R2, #1
    LDR R3, POINT
    STR R1, [R3], #4
    STR R2, [R3], #4
    LOOP: SUB R4, R4, R4  @make R4 zero
    ADD R4, R2
    ADD R2, R1
    SUB R1, R1, R1
    ADD R1, R4
    STR R2,[R3],#4
    SUBS R0, #1
    BNE LOOP
    
N: .word 10
MEMLOC: .word 0
POINT: .word MEMLOC
`;

const AssemblyCodeContext = createContext<AssemblyCodePost>({
  assemblyCodeWritten: initialisedAssemblyCodeWritten,
  setAssemblyCodeWritten: (assemblyCodeWritten: string) => {},
});

const assemblyCodeStorageKey: string = "assemblyCode";

const getLocalStorage = (
  key: string,
  initialisedAssemblyCode: string
): string => {
  const assemblyCodeData: string | null = window.localStorage.getItem(key);
  if (assemblyCodeData === null) {
    return initialisedAssemblyCode;
  }
  return JSON.parse(assemblyCodeData);
};

const AssemblyCodeContextHandler: React.FC<ChildComponentsProp> = ({
  children,
}: ChildComponentsProp) => {
  const [assemblyCodeWritten, setAssemblyCodeWritten] = useState<string>(() =>
    getLocalStorage(assemblyCodeStorageKey, initialisedAssemblyCodeWritten)
  );

  useEffect(() => {
    window.localStorage.setItem(
      assemblyCodeStorageKey,
      JSON.stringify(assemblyCodeWritten)
    );
  }, [assemblyCodeWritten]);

  const assemblyCodeValue = useMemo<AssemblyCodePost>(
    () => ({ assemblyCodeWritten, setAssemblyCodeWritten }),
    [assemblyCodeWritten]
  );

  return (
    <AssemblyCodeContext.Provider value={assemblyCodeValue}>
      {children}
    </AssemblyCodeContext.Provider>
  );
};

const useAssemblyCodePostHandler = (): AssemblyCodePost => {
  return useContext<AssemblyCodePost>(AssemblyCodeContext);
};

export { AssemblyCodeContextHandler, useAssemblyCodePostHandler };
