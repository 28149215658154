import { useEffect } from "react";
import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_TRACKING_ID: string = process.env
  .REACT_APP_GA_TRACKING_ID as string;
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const useTrack = (pageTitle: string) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: pageTitle,
    });
  });
};

export default useTrack;
