import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { CustomHandle, handleOffsetDifference } from "../CustomHandle";
import GeneralNodeNoTable from "../GeneralNodeNoTable";

const nodeHeight: number = 90;
const nodeWidth: number = 25;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleOffsetAtSide: number = 9.5;

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowTwo = {
  top: handleOffsetAtSide + handleOffsetDifference,
};

const handleStyleRowThree = {
  top: handleOffsetAtSide + handleOffsetDifference * 2,
};

const handleStyleRowFour = {
  top: handleOffsetAtSide + handleOffsetDifference * 3,
};

const handleStyleRowFive = {
  top: handleOffsetAtSide + handleOffsetDifference * 4,
};

const StatusNode: React.FC = () => {
  return (
    <>
      <GeneralNodeNoTable name="Status" gridStyle={gridStyle} />
      <CustomHandle
        id="D-StatusNode"
        type="target"
        position={Position.Bottom}
        toolTipLabel={"D"}
        handleStyle={{}}
      />
      <CustomHandle
        id="En-StatusNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"En"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="N-StatusNode"
        type="source"
        position={Position.Left}
        toolTipLabel={"N"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="C-StatusNode"
        type="source"
        position={Position.Left}
        toolTipLabel={"C"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="V-StatusNode"
        type="source"
        position={Position.Left}
        toolTipLabel={"V"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="Z-StatusNode"
        type="source"
        position={Position.Left}
        toolTipLabel={"Z"}
        handleStyle={handleStyleRowFour}
      />
    </>
  );
};

export default StatusNode;
