import { Grid } from "@mui/material";
import CodePlatform from "../components/CodePlatform";
import { useMobileViewHandler } from "../components/context/MobileViewContextHandler";
import Processor from "../components/Processor";
import useTrack from "../ga/useTrack";

const SimulationViewer: React.FC = () => {
  const isMobile = useMobileViewHandler();

  useTrack("Simulation Page");

  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "95%",
      }}
    >
      <Grid
        item
        xs={isMobile ? 12 : 2.5}
        sx={{ boxShadow: "5", width: "100%", height: "100%" }}
      >
        <CodePlatform />
      </Grid>
      <Grid item sx={{ flexGrow: 1, height: "100%" }}>
        <Processor />
      </Grid>
    </Grid>
  );
};

export default SimulationViewer;
