import { Box, Grid, SxProps, Typography } from "@mui/material";
import React from "react";

const headerGridStyle: SxProps = {
  backgroundColor: "#1F1F25",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  boxShadow: 3,
};

const contentGridStyle: SxProps = {
  height: "100%",
  backgroundColor: "#A4BAB7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  boxShadow: 3,
};

interface GeneralNodeProps {
  header: string;
  gridStyle: SxProps;
  table: React.ReactNode;
}

const GeneralNode: React.FC<GeneralNodeProps> = ({
  header,
  gridStyle,
  table,
}: GeneralNodeProps) => {
  return (
    <Grid container direction="column" sx={gridStyle}>
      <Grid item xs={1} sx={headerGridStyle}>
        <Typography sx={{ fontSize: 6.5 }} color="white">
          {header}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Grid container sx={contentGridStyle}>
          <Box
            sx={{
              padding: "2px",
            }}
          >
            {table}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralNode;
