import { Paper, SxProps, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { AddressToLineAndMachineCodeData } from "../../types/AddressMappers";

const tableContainerStyle: SxProps = {
  border: "1px solid rgba(0, 0,0,0.2)",
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  maxHeight: "100px",
  maxWidth: "74px",
  "&::-webkit-scrollbar": {
    height: 5,
    width: 5,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#C1C1C1",
    borderRadius: 2,
  },
};

const tableHeaderStyle: SxProps = {
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "1px",
};

const tableTextStyle: SxProps = {
  fontSize: 6,
  fontColor: "white",
};

interface InstructionMemoryTableProps {
  instructionMemoryDataList: AddressToLineAndMachineCodeData[];
  clearState: boolean;
  currentPCValue: number;
}

const InstructionMemoryTable: React.FC<InstructionMemoryTableProps> = ({
  instructionMemoryDataList,
  clearState,
  currentPCValue,
}: InstructionMemoryTableProps) => {
  return (
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table
        stickyHeader
        sx={{ height: "max-content" }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Address</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Line</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Code</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {instructionMemoryDataList.map(
            (data: AddressToLineAndMachineCodeData) => (
              <TableRow
                sx={{
                  backgroundColor:
                    !clearState && currentPCValue === data.address
                      ? "#C1C1C1"
                      : "white",
                }}
                key={data.address}
              >
                <TableCell sx={{ padding: "0px 0px" }} align="center">
                  <Typography sx={tableTextStyle}>0x{data.address.toString(16)}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px",
                  }}
                  align="center"
                >
                  <Typography sx={tableTextStyle}>
                    {data.instructionLineAndMachineCode.lineNumber}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "0px 1px" }} align="center">
                  <Typography sx={tableTextStyle}>
                    0x{data.instructionLineAndMachineCode.machineCode.toString(16)}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstructionMemoryTable;
