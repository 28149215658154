import { Route, Routes } from "react-router-dom";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";

import SimulationViewer from "../pages/SimulationViewer";
import UserGuide from "../pages/UserGuide";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SimulationViewer />} />
      <Route path="/userguide" element={<UserGuide />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
