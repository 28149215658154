import { Paper, SxProps, Typography } from "@mui/material";

const paperStyle: SxProps = {
  height: "100%",
  borderRadius: "0px",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  backgroundColor: "#1F1F25",
  padding: "10px",
};

const EditorHeader: React.FC = () => {
  return (
    <Paper sx={paperStyle}>
      <Typography sx={{ fontSize: 20 }} color="white">
        ARM-RISC Assembly
      </Typography>
    </Paper>
  );
};

export default EditorHeader;
