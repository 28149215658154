import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, IconButton, SxProps, Typography } from "@mui/material";

import { useMobileViewHandler } from "../components/context/MobileViewContextHandler";
import useTrack from "../ga/useTrack";

const desktopGridStyle: SxProps = {
  boxShadow: "5",
  padding: "30px",
  paddingLeft: "80px",
  paddingRight: "80px",
  paddingTop: "80px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const mobileGridStyle: SxProps = {
  padding: "10px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const profileGridStyle: SxProps = {
  height: "30%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const iconButtonStyle: SxProps = {
  height: "60%",
  width: "15%",
};

const iconStyle: SxProps = {
  color: "black",
  height: "100%",
  width: "100%",
};

const Contact: React.FC = () => {
  const isMobile = useMobileViewHandler();
  const openSocialMediaTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useTrack("Contact Page");

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "93%",
      }}
    >
      <Grid item xs={isMobile ? 0 : 3} />
      <Grid
        item
        xs={isMobile ? 12 : 6}
        sx={isMobile ? mobileGridStyle : desktopGridStyle}
        container
        direction="column"
      >
        <Grid item>
          <Typography variant="h4">
            Thank you so much for viewing the project! If you like the project,
            feel free to connect with me below!
          </Typography>
        </Grid>
        <Grid item container direction="column" sx={{ height: "50%" }}>
          <Grid item sx={profileGridStyle}>
            <IconButton
              size="large"
              sx={iconButtonStyle}
              onClick={() =>
                openSocialMediaTab("https://github.com/theodorekwok")
              }
            >
              <GitHubIcon sx={iconStyle} />
            </IconButton>
            <Typography variant="h6">Github Profile: theodorekwok</Typography>
          </Grid>
          <Grid item xs={3} sx={profileGridStyle}>
            <IconButton
              size="large"
              sx={iconButtonStyle}
              onClick={() =>
                openSocialMediaTab("https://www.linkedin.com/in/theodore-kxs")
              }
            >
              <LinkedInIcon sx={iconStyle} />
            </IconButton>
            <Typography variant="h6">LinkedIn Profile: theodore-kxs</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 0 : 3} />
    </Grid>
  );
};

export default Contact;
