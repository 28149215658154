import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { CustomHandle, handleOffsetDifference } from "../CustomHandle";
import GeneralNodeNoTable from "../GeneralNodeNoTable";

const nodeHeight: number = 70;
const nodeWidth: number = 30;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
  backgroundColor: "#A4BAB7",
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  boxShadow: 3,
};

const handleOffsetAtSide: number =
  (nodeHeight - 3 * handleOffsetDifference) / 2;

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowTwo = {
  top: handleOffsetAtSide + handleOffsetDifference,
};

const handleStyleRowThree = {
  top: handleOffsetAtSide + handleOffsetDifference * 2,
};

const handleStyleRowFour = {
  top: handleOffsetAtSide + handleOffsetDifference * 3,
};

const ConditionCheckNode: React.FC = () => {
  return (
    <>
      <GeneralNodeNoTable name="Condition Check" gridStyle={gridStyle} />
      <CustomHandle
        id="PCSrc-ConditionCheckNode"
        type="source"
        position={Position.Left}
        toolTipLabel={"PCSrc"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="Condition-ConditionCheckNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"Condition"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="PCS-ConditionCheckNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"PCS"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="N-ConditionCheckNode"
        type="target"
        position={Position.Right}
        toolTipLabel={"N"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="C-ConditionCheckNode"
        type="target"
        position={Position.Right}
        toolTipLabel={"C"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="V-ConditionCheckNode"
        type="target"
        position={Position.Right}
        toolTipLabel={"V"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="Z-ConditionCheckNode"
        type="target"
        position={Position.Right}
        toolTipLabel={"Z"}
        handleStyle={handleStyleRowFour}
      />
    </>
  );
};

export default ConditionCheckNode;
