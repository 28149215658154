import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, SxProps } from "@mui/material";
import SubmitChangeInstruction from "../../../api/SubmitChangeInstruction";

import { useExecutionStateReponseHandler } from "../../context/InstructionExecutionStateContextHandler";
import BACKEND_PATH from "../../../api/BackendPath";

const buttonStyle: SxProps = {
  borderRadius: "5px",
  backgroundColor: "#75F8AA",
  fontSize: "8px",
  padding: "1px",
  color: "black",
  "&:hover": {
    background: "#75F8AA",
  },
};

const NextInstructionNode: React.FC = () => {
  const { setExecutionStateResponseData } = useExecutionStateReponseHandler();

  const submitNextInstruction = () => {
    SubmitChangeInstruction(
      BACKEND_PATH + "/nextinstruction",
      setExecutionStateResponseData
    );
  };

  return (
    <Button
      sx={buttonStyle}
      variant="contained"
      endIcon={<ArrowForwardIosIcon sx={{ height: "10px" }} />}
      onClick={submitNextInstruction}
    >
      Next
    </Button>
  );
};

export default NextInstructionNode;
