import InfoIcon from "@mui/icons-material/Info";
import { Paper, SxProps, Typography } from "@mui/material";

import { useExecutionStateReponseHandler } from "../../context/InstructionExecutionStateContextHandler";

const paperStyle: SxProps = {
  height: "30px",
  width: "137px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#75F8AA",
};

const textStyle: SxProps = {
  fontSize: 9,
};

const ExecutionCommentNode: React.FC = () => {
  const { executionStateResponseData } = useExecutionStateReponseHandler();

  return (
    <Paper sx={paperStyle}>
      <InfoIcon sx={{ height: "10px", width: "20px" }} />
      <Typography sx={textStyle}>
        {executionStateResponseData.executionComment}
      </Typography>
    </Paper>
  );
};

export default ExecutionCommentNode;
