export const instructionUtilityNodes = [
  {
    id: "instructionUtility",
    position: { x: 200, y: -120 },
    type: "group",
    style: { border: "1px solid rgba(0, 0,0,0.2)", width: 150, height: 65 },
    data: "",
  },
  {
    id: "previousInstructionNode",
    type: "previousInstructionNode",
    data: "",
    position: { x: 6, y: 3 },
    parentNode: "instructionUtility",
  },
  {
    id: "nextInstructionNode",
    type: "nextInstructionNode",
    data: "",
    position: { x: 80, y: 3 },
    parentNode: "instructionUtility",
  },
  {
    id: "executionCommentNode",
    type: "executionCommentNode",
    data: "",
    position: { x: 6, y: 27 },
    parentNode: "instructionUtility",
  },
];
