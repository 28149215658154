import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, SxProps } from "@mui/material";
import SubmitChangeInstruction from "../../../api/SubmitChangeInstruction";

import { useExecutionStateReponseHandler } from "../../context/InstructionExecutionStateContextHandler";
import BACKEND_PATH from "../../../api/BackendPath";

const buttonStyle: SxProps = {
  borderRadius: "5px",
  backgroundColor: "#75F8AA",
  fontSize: "8px",
  padding: "1px",
  paddingRight: "5px",
  color: "black",
  "&:hover": {
    background: "#75F8AA",
  },
};

const PreviousInstructionNode: React.FC = () => {
  const { setExecutionStateResponseData } = useExecutionStateReponseHandler();

  const submitPreviousInstruction = () => {
    SubmitChangeInstruction(
      BACKEND_PATH +"/previousinstruction",
      setExecutionStateResponseData
    );
  };

  return (
    <Button
      sx={buttonStyle}
      variant="contained"
      startIcon={
        <ArrowBackIosIcon sx={{ height: "10px", paddingLeft: "5px" }} />
      }
      onClick={submitPreviousInstruction}
    >
      Prev
    </Button>
  );
};

export default PreviousInstructionNode;
