import { Paper, SxProps, TableHead, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { MachineCodeWireData } from "../../types/InstructionWire";

const tableContainerStyle: SxProps = {
  border: "1px solid rgba(0, 0,0,0.2)",
  width: "max-content",
  maxHeight: "120px",
  maxWidth: "50px",
};

const tableHeaderStyle: SxProps = {
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "1px",
};

const tableTextStyle: SxProps = {
  fontSize: 6,
  paddingTop: "1px",
  paddingBottom: "1px",
};

interface DecoderTableProps {
  isClearState: boolean;
  machineWireState: MachineCodeWireData;
}

interface DecoderRowInformation {
  input: string;
  value: number;
}

const getValueOfDecoderInput = (
  isClearState: boolean,
  value: number
): string => {
  if (isClearState) {
    return "?";
  }
  return value.toString();
};

const createRowInformation = (
  wireComponent: number,
  input: string
): DecoderRowInformation => {
  return {
    input: input,
    value: wireComponent,
  };
};

const DecoderTable: React.FC<DecoderTableProps> = ({
  isClearState,
  machineWireState,
}: DecoderTableProps) => {
  const rowInfo = [
    {
      wireComponent: machineWireState.opSegment,
      input: "OP",
    },
    {
      wireComponent: machineWireState.sBitSegment,
      input: "S",
    },
    {
      wireComponent: machineWireState.wBitSegment,
      input: "W",
    },
    {
      wireComponent: machineWireState.uBitSegment,
      input: "U",
    },
    {
      wireComponent: machineWireState.pBitSegment,
      input: "P",
    },
    {
      wireComponent: machineWireState.lBitSegment,
      input: "L",
    },
    {
      wireComponent: machineWireState.iBitSegment,
      input: "I",
    },
  ];

  const generateRowDataList = (): DecoderRowInformation[] => {
    let rowDataResult: DecoderRowInformation[] = [];
    rowInfo.forEach((item: { wireComponent: number; input: string }) => {
      rowDataResult.push(createRowInformation(item.wireComponent, item.input));
    });
    return rowDataResult;
  };

  const decoderRowDataList = generateRowDataList();

  return (
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table
        stickyHeader
        sx={{ height: "max-content" }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Input</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {decoderRowDataList.map((data: DecoderRowInformation) => (
            <TableRow
              sx={{
                backgroundColor: "white",
              }}
              key={data.input}
            >
              <TableCell sx={{ padding: "0px 0px" }} align="center">
                <Typography sx={tableTextStyle}>{data.input}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px 0px",
                }}
                align="center"
              >
                <Typography sx={tableTextStyle}>
                  {getValueOfDecoderInput(isClearState, data.value)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DecoderTable;
