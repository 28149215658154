import { AssemblyCodeData } from "../types/AssemblyCode";
import { InitialStateData } from "../types/InitialState";
import BACKEND_PATH from "./BackendPath";

const Submit = (
  assemblyCodeData: AssemblyCodeData,
  responseHandler: (initialStateResponse: InitialStateData) => void
) => {
  fetch(BACKEND_PATH + "/submit", {
    method: "POST",
    body: JSON.stringify(assemblyCodeData),
    headers: { "Content-Type": "application/json" },
  })
    .then((res: Response) => res.json())
    .then((initialStateResponse: InitialStateData) => {
      responseHandler(initialStateResponse);
    });
};

export default Submit;
