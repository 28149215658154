import { Grid, SxProps, Typography } from "@mui/material";

const textStyle: SxProps = {
  fontSize: 7,
  width: "100%",
};

interface GeneralNodeNoTableProps {
  name: string;
  gridStyle: SxProps;
  isRotate?: boolean;
}

const GeneralNodeNoTable: React.FC<GeneralNodeNoTableProps> = ({
  name,
  gridStyle,
  isRotate = true,
}: GeneralNodeNoTableProps) => {
  const rotationDegree: number = isRotate ? 270 : 0;
  return (
    <Grid
      sx={{
        ...gridStyle,
        backgroundColor: "#A4BAB7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          ...textStyle,
          transform: "rotate(" + rotationDegree.toString() + "deg)",
        }}
        align="center"
      >
        {name}
      </Typography>
    </Grid>
  );
};

export default GeneralNodeNoTable;
