import IntermediateNode from "./IntermediateNode";

const createIntermediateNode = (
  name: string,
  xPosition: number,
  yPosition: number
) => {
  let nodeId: string = name + "IntermediateNode";
  return [
    {
      id: nodeId,
      type: nodeId,
      position: { x: xPosition, y: yPosition },
      data: "",
    },
  ];
};

export const IntermediateNodeTypes = {
  rdIntermediateNode: IntermediateNode,

  immediateIntermediateNode: IntermediateNode,
  secondImmediateIntermediateNode: IntermediateNode,

  pcAddFourIntermediateNode: IntermediateNode,
  secondPCAddFourIntermediateNode: IntermediateNode,
  thirdPCAddFourIntermediateNode: IntermediateNode,

  rdTwoIntermediateNode: IntermediateNode,

  aluResultAfterMultiplexerIntermediateNode: IntermediateNode,
  aluResultIntermediateNode: IntermediateNode,
  secondALUResultIntermediateNode: IntermediateNode,

  resultIntermediateNode: IntermediateNode,
  secondResultIntermediateNode: IntermediateNode,
  thirdResultIntermediateNode: IntermediateNode,
};

export const IntermediateNodes = [
  ...createIntermediateNode("rd", -135, 133),
  ...createIntermediateNode("immediate", -135, 205),
  ...createIntermediateNode("secondImmediate", 50, 205),
  ...createIntermediateNode("pcAddFour", 32, 215),
  ...createIntermediateNode("secondPCAddFour", -165, 230),
  ...createIntermediateNode("thirdPCAddFour", -280, 230),
  ...createIntermediateNode("rdTwo", 40, 180),
  ...createIntermediateNode("aluResultAfterMultiplexer", 187, 225),
  ...createIntermediateNode("aluResult", 160, 230),
  ...createIntermediateNode("secondALUResult", -98, 230),
  ...createIntermediateNode("result", 350, 250),
  ...createIntermediateNode("secondResult", -80, 250),
  ...createIntermediateNode("thirdResult", -300, 250),
];
