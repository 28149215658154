import { Grid, SxProps, Typography } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";
import { CustomHandle } from "../CustomHandle";

const multiplexerNodeHeight: number = 30;
const multiplexerNodeWidth: number = 15;

const nodeGridStyle: SxProps = {
  height: multiplexerNodeHeight.toString() + "px",
  width: multiplexerNodeWidth.toString() + "px",
  backgroundColor: "#A4BAB7",
  borderRadius: "5px",
  boxShadow: 3,
};

const textGridStyle: SxProps = {
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const textStyle: SxProps = {
  fontSize: 7,
};

const multiplexerHandleStyleTopInput = {
  top: multiplexerNodeHeight / 4,
};

const multiplexerHandleStyleBottomInput = {
  top: (multiplexerNodeHeight * 3) / 4,
};

interface MultiplexerGeneralNodeProps {
  topInput: string;
  bottomInput: string;
  leftTopId: string;
  leftBottomId: string;
  topId: string;
  rightId: string;
}

const MultiplexerGeneralNode: React.FC<MultiplexerGeneralNodeProps> = ({
  topInput,
  bottomInput,
  leftTopId,
  leftBottomId,
  topId,
  rightId,
}: MultiplexerGeneralNodeProps) => {
  return (
    <>
      <Grid container direction="column" sx={nodeGridStyle}>
        <Grid item sx={textGridStyle} xs={6}>
          <Typography sx={textStyle}>{topInput}</Typography>
        </Grid>
        <Grid item sx={textGridStyle} xs={6}>
          <Typography sx={textStyle}>{bottomInput}</Typography>
        </Grid>
      </Grid>
      <CustomHandle
        id={leftTopId}
        type="target"
        position={Position.Left}
        toolTipLabel={""}
        isDisableTooltip={true}
        handleStyle={multiplexerHandleStyleTopInput}
      />
      <CustomHandle
        id={leftBottomId}
        type="target"
        position={Position.Left}
        toolTipLabel={""}
        isDisableTooltip={true}
        handleStyle={multiplexerHandleStyleBottomInput}
      />
      <CustomHandle
        id={topId}
        type="target"
        position={Position.Top}
        toolTipLabel={""}
        isDisableTooltip={true}
        handleStyle={{}}
      />
      <CustomHandle
        id={rightId}
        type="source"
        position={Position.Right}
        toolTipLabel={""}
        isDisableTooltip={true}
        handleStyle={{}}
      />
    </>
  );
};

export default MultiplexerGeneralNode;
