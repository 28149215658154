import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { useInitialReponseHandler } from "../context/InitialResponseContextHandler";
import { useExecutionStateReponseHandler } from "../context/InstructionExecutionStateContextHandler";
import InstructionMemoryTable from "../tables/InstructionMemoryTable";
import { CustomHandle, handleOffsetAtSide } from "./CustomHandle";
import GeneralNode from "./GeneralNode";

const nodeHeight: number = 125;
const nodeWidth: number = 85;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleStyle = {
  top: handleOffsetAtSide,
};

const InstructionMemoryNode: React.FC = () => {
  const { initialResponseData } = useInitialReponseHandler();
  const { executionStateResponseData } = useExecutionStateReponseHandler();

  const table: React.ReactNode = (
    <InstructionMemoryTable
      instructionMemoryDataList={initialResponseData.instructionMemoryState}
      clearState={executionStateResponseData.clearState}
      currentPCValue={
        executionStateResponseData.instructionWireDetails.machineCodeWires
          .currentPCValue
      }
    />
  );

  return (
    <>
      <GeneralNode
        header="Instruction Memory"
        gridStyle={gridStyle}
        table={table}
      />
      <CustomHandle
        id="A-InstructionMemoryNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"A"}
        handleStyle={handleStyle}
      />
      <CustomHandle
        id="RD-InstructionMemoryNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RD"}
        handleStyle={handleStyle}
      />
    </>
  );
};

export default InstructionMemoryNode;
