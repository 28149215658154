import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";
import { useInitialReponseHandler } from "../context/InitialResponseContextHandler";

import { useExecutionStateReponseHandler } from "../context/InstructionExecutionStateContextHandler";
import DataMemoryTable from "../tables/DataMemoryTable";
import {
  CustomHandle,
  handleOffsetAtSide,
  handleOffsetAtTop,
  handleOffsetDifference,
} from "./CustomHandle";
import GeneralNode from "./GeneralNode";

const nodeHeight: number = 120;
const nodeWidth: number = 85;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowFive = {
  top: handleOffsetAtSide + handleOffsetDifference * 4,
};

const handleStyleTopRight = {
  left: nodeWidth - handleOffsetAtTop,
};

const DataMemoryNode: React.FC = () => {
  const { initialResponseData } = useInitialReponseHandler();
  const { executionStateResponseData } = useExecutionStateReponseHandler();

  const table: React.ReactNode = (
    <DataMemoryTable
      dataMemoryDataList={executionStateResponseData.dataMemoryState}
      addressToLabelDataList={initialResponseData.addressToLabels}
    />
  );

  return (
    <>
      <GeneralNode header="Data Memory" gridStyle={gridStyle} table={table} />
      <CustomHandle
        id="A-DataMemoryNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"A"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="WD-DataMemoryNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"WD"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="RD-DataMemoryNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RD"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="WE-DataMemoryNode"
        type="target"
        position={Position.Top}
        toolTipLabel={"WE"}
        handleStyle={handleStyleTopRight}
      />
    </>
  );
};

export default DataMemoryNode;
