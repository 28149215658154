import { Typography } from "@mui/material";
import React from "react";
import {
  getEdgeCenter,
  getSmoothStepPath,
  Position,
} from "react-flow-renderer";

const foreignObjectSize = 120;

export interface EdgeTextDetails {
  text: string;
  xOffset: number;
  yOffset: number;
}

interface CustomEdgeProps {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: Position;
  targetPosition: Position;
  data?: EdgeTextDetails;
}

const CustomEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data = { text: "", xOffset: 0, yOffset: 0 },
}: CustomEdgeProps) => {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path id={id} className="react-flow__edge-path" d={edgePath} />
      {data.text !== "" && (
        <foreignObject
          width={foreignObjectSize}
          height={10}
          x={edgeCenterX + data?.xOffset}
          y={edgeCenterY + data?.yOffset}
        >
          <Typography sx={{ fontSize: 7, width: "110px" }}>
            {data?.text}
          </Typography>
        </foreignObject>
      )}
    </>
  );
};

export default CustomEdge;
