import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const RegisterFirstOutputMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="0-RegisterFirstOutputMultiplexerNode"
      leftBottomId="1-RegisterFirstOutputMultiplexerNode"
      topId="top-RegisterFirstOutputMultiplexerNode"
      rightId="output-RegisterFirstOutputMultiplexerNode"
      topInput="0"
      bottomInput="1"
    />
  );
};

export default RegisterFirstOutputMultiplexerNode;
