import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";
import { useExecutionStateReponseHandler } from "../context/InstructionExecutionStateContextHandler";

import RegisterFileTable from "../tables/RegisterFileTable";
import {
  CustomHandle,
  handleOffsetAtSide,
  handleOffsetAtTop,
} from "./CustomHandle";
import GeneralNode from "./GeneralNode";

const nodeHeight: number = 120;
const nodeWidth: number = 75;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleOffsetDifference: number = 23;

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowTwo = {
  top: handleOffsetAtSide + handleOffsetDifference,
};

const handleStyleRowThree = {
  top: handleOffsetAtSide + handleOffsetDifference * 2,
};

const handleStyleRowFour = {
  top: handleOffsetAtSide + handleOffsetDifference * 3,
};

const handleStyleRowFive = {
  top: handleOffsetAtSide + handleOffsetDifference * 4,
};

const handleStyleTopLeft = {
  left: handleOffsetAtTop,
};

const handleStyleTopRight = {
  left: nodeWidth - handleOffsetAtTop,
};

const RegisterFileNode: React.FC = () => {
  const { executionStateResponseData } = useExecutionStateReponseHandler();

  const table: React.ReactNode = (
    <RegisterFileTable
      registerFileDataList={executionStateResponseData.registerFileState}
    />
  );

  return (
    <>
      <GeneralNode header="Register File" gridStyle={gridStyle} table={table} />
      <CustomHandle
        id="A1-RegisterFileNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"A1"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="A2-RegisterFileNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"A2"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="A3-RegisterFileNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"A3"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="WD1-RegisterFileNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"WD1"}
        handleStyle={handleStyleRowFour}
      />
      <CustomHandle
        id="WD3-RegisterFileNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"WD3"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="RD1-RegisterFileNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RD1"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="RD2-RegisterFileNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RD2"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="WE1-RegisterFileNode"
        type="target"
        position={Position.Top}
        toolTipLabel={"WE1"}
        handleStyle={handleStyleTopLeft}
      />
      <CustomHandle
        id="WE3-RegisterFileNode"
        type="target"
        position={Position.Top}
        toolTipLabel={"WE3"}
        handleStyle={handleStyleTopRight}
      />
    </>
  );
};

export default RegisterFileNode;
