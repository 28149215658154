import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import HeadingRenderer from "../components/markdown/HeadingRenderer";
import useTrack from "../ga/useTrack";

const UserGuide: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    fetch("UserGuide.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  useTrack("User Guide Page");

  return (
    <Grid container sx={{ height: "100%", width: "100%" }}>
      <Grid item xs={2} />
      <Grid
        item
        xs={8}
        sx={{
          boxShadow: "5",
          padding: "30px",
          paddingLeft: "80px",
          paddingRight: "80px",
        }}
      >
        <ReactMarkdown
          children={content}
          components={{
            h1: HeadingRenderer,
            h2: HeadingRenderer,
            h3: HeadingRenderer,
            img: ({
              alt,
              src,
              title,
            }: {
              alt?: string;
              src?: string;
              title?: string;
            }) => (
              <img
                alt={alt}
                src={src}
                title={title}
                style={{ maxWidth: 475 }}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default UserGuide;
