import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { InitialStateData } from "../../types/InitialState";

export interface InitialResponse {
  initialResponseData: InitialStateData;
  setInitialResponseData: (initialStateResponse: InitialStateData) => void;
}

interface ChildComponentsProp {
  children: React.ReactNode;
}

const initialisedInitialResponseData: InitialStateData = {
  hasError: false,
  assemblerMessage: "No assembler logs",
  instructionMemoryState: [],
  dataMemoryState: [],
  addressToLabels: [],
};

const InitialResponseContext = createContext<InitialResponse>({
  initialResponseData: initialisedInitialResponseData,
  setInitialResponseData: (initialStateResponse: InitialStateData) => {},
});

const initialResponseDataStorageKey: string = "initialResponseData";

const getLocalStorage = (
  key: string,
  initialisedInitialStateData: InitialStateData
): InitialStateData => {
  const initialStateData: string | null = window.localStorage.getItem(key);
  if (initialStateData === null) {
    return initialisedInitialStateData;
  }
  return JSON.parse(initialStateData);
};

const InitialResponseContextHandler: React.FC<ChildComponentsProp> = ({
  children,
}: ChildComponentsProp) => {
  const [initialResponseData, setInitialResponseData] =
    useState<InitialStateData>(() =>
      getLocalStorage(
        initialResponseDataStorageKey,
        initialisedInitialResponseData
      )
    );

  useEffect(() => {
    window.localStorage.setItem(
      initialResponseDataStorageKey,
      JSON.stringify(initialResponseData)
    );
  }, [initialResponseData]);

  const initialResponseValue = useMemo<InitialResponse>(
    () => ({ initialResponseData, setInitialResponseData }),
    [initialResponseData]
  );

  return (
    <InitialResponseContext.Provider value={initialResponseValue}>
      {children}
    </InitialResponseContext.Provider>
  );
};

const useInitialReponseHandler = (): InitialResponse => {
  return useContext<InitialResponse>(InitialResponseContext);
};

export { InitialResponseContextHandler, useInitialReponseHandler };
