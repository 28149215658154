import PCIncrementInputMultiplexerNode from "./multiplexers/PCIncrementInputMultiplexerNode";
import RegisterFirstOutputMultiplexerNode from "./multiplexers/RegisterFirstOutputMultiplexerNode";
import RegisterSecondInputMultiplexerNode from "./multiplexers/RegisterSecondInputMultiplexerNode";
import RegisterSecondOutputMultiplexerNode from "./multiplexers/RegisterSecondOutputMultiplexerNode";
import ResultMultiplexerNode from "./multiplexers/ResultMultiplexerNode";
import TargetAddressMultiplexerNode from "./multiplexers/TargetAddressMultiplexerNode";

export const MultiplexerNodeTypes = {
  pcIncrementInputMultiplexerNode: PCIncrementInputMultiplexerNode,
  registerFirstOutputMultiplexerNode: RegisterFirstOutputMultiplexerNode,
  registerSecondInputMultiplexerNode: RegisterSecondInputMultiplexerNode,
  registerSecondOutputMultiplexerNode: RegisterSecondOutputMultiplexerNode,
  targetAddressMultiplexerNode: TargetAddressMultiplexerNode,
  resultMultiplexerNode: ResultMultiplexerNode,
};

export const MultiplexerNodes = [
  {
    id: "pcIncrementInputMultiplexerNode",
    type: "pcIncrementInputMultiplexerNode",
    position: { x: -270, y: -30 },
    data: "",
  },
  {
    id: "registerSecondInputMultiplexerNode",
    type: "registerSecondInputMultiplexerNode",
    position: { x: -92.5, y: 98 },
    data: "",
  },
  {
    id: "registerFirstOutputMultiplexerNode",
    type: "registerFirstOutputMultiplexerNode",
    position: { x: 58, y: 82.5 },
    data: "",
  },
  {
    id: "registerSecondOutputMultiplexerNode",
    type: "registerSecondOutputMultiplexerNode",
    position: { x: 70, y: 128.5 },
    data: "",
  },
  {
    id: "targetAddressMultiplexerNode",
    type: "targetAddressMultiplexerNode",
    position: { x: 150, y: 60 },
    data: "",
  },
  {
    id: "resultMultiplexerNode",
    type: "resultMultiplexerNode",
    position: { x: 320, y: 200 },
    data: "",
  },
];
