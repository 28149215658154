const conditionalNodes = [
  {
    id: "conditionalUnit",
    position: { x: 75, y: -120 },
    type: "group",
    style: { border: "1px solid rgba(0, 0,0,0.2)", width: 120, height: 105 },
    data: "",
  },
  {
    id: "conditionCheckNode",
    type: "conditionCheckNode",
    data: "",
    position: { x: 15, y: 5 },
    parentNode: "conditionalUnit",
  },
  {
    id: "statusNode",
    type: "statusNode",
    data: "",
    position: { x: 80, y: 5 },
    parentNode: "conditionalUnit",
  },
];

export default conditionalNodes;
