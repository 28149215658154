import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const ResultMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="1-ResultMultiplexerNode"
      leftBottomId="0-ResultMultiplexerNode"
      topId="top-ResultMultiplexerNode"
      rightId="output-ResultMultiplexerNode"
      topInput="1"
      bottomInput="0"
    />
  );
};

export default ResultMultiplexerNode;
