import { Grid, Paper, SxProps, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { RegisterToValueData } from "../../types/RegisterMapper";

const tableContainerStyle: SxProps = {
  border: "1px solid rgba(0, 0,0,0.2)",
  width: "max-content",
  maxHeight: "100px",
  maxWidth: "65px",
};

const tableHeaderStyle: SxProps = {
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "1px",
};

const tableTextStyle: SxProps = {
  fontSize: 6,
  fontColor: "white",
  paddingTop: "1px",
  paddingBottom: "1px",
};

const fixedRegisterNumberListOne: number[] = [
  0, 1, 2, 3, 4, 5, 6
];

const fixedRegisterNumberListTwo: number[] = [
  7, 8, 9, 10, 11, 12, 15
];

interface RegisterFileTableProps {
  registerFileDataList: RegisterToValueData[];
}

const RegisterFileTable: React.FC<RegisterFileTableProps> = ({
  registerFileDataList,
}: RegisterFileTableProps) => {
  const findRegisterFileData = (registerId: number): string => {
    let registerValue: string = "0x0";
    registerFileDataList.forEach((registerToValue: RegisterToValueData) => {
      if (registerToValue.registerId === registerId) {
        registerValue = "0x" + registerToValue.value.toString(16);
      }
    });
    return registerValue;
  };

  return (
    <Grid container>
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table
        stickyHeader
        sx={{ height: "max-content" }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Reg</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fixedRegisterNumberListOne.map((registerId: number) => (
            <TableRow
              sx={{
                backgroundColor: "white",
              }}
              key={registerId}
            >
              <TableCell sx={{ padding: "0px 0px" }} align="center">
                <Typography sx={tableTextStyle}>{registerId}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px 0px",
                }}
                align="center"
              >
                <Typography sx={tableTextStyle}>
                  {findRegisterFileData(registerId)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table
        stickyHeader
        sx={{ height: "max-content" }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Reg</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fixedRegisterNumberListTwo.map((registerId: number) => (
            <TableRow
              sx={{
                backgroundColor: "white",
              }}
              key={registerId}
            >
              <TableCell sx={{ padding: "0px 0px" }} align="center">
                <Typography sx={tableTextStyle}>{registerId}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px 0px",
                }}
                align="center"
              >
                <Typography sx={tableTextStyle}>
                  {findRegisterFileData(registerId)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
  );
};

export default RegisterFileTable;
