import { Box } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import "./App.css";
import { AssemblyCodeContextHandler } from "./components/context/AssemblyCodeContextHandler";
import { InitialResponseContextHandler } from "./components/context/InitialResponseContextHandler";
import { InstructionExecutionStateContextHandler } from "./components/context/InstructionExecutionStateContextHandler";
import { MobileViewContextHandler } from "./components/context/MobileViewContextHandler";
import NavigationBar from "./components/NavigationBar";
import AppRoutes from "./routes/AppRoutes";

const App: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BrowserRouter>
        <NavigationBar />
        <MobileViewContextHandler>
          <AssemblyCodeContextHandler>
            <InitialResponseContextHandler>
              <InstructionExecutionStateContextHandler>
                <AppRoutes />
              </InstructionExecutionStateContextHandler>
            </InitialResponseContextHandler>
          </AssemblyCodeContextHandler>
        </MobileViewContextHandler>
      </BrowserRouter>
    </Box>
  );
};

export default App;
