import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();

  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" sx={{ backgroundColor: "#A4BAB7" }}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            RISC-VISUAL
          </Typography>
          <Button sx={{ color: "black" }} onClick={() => handleClick("/")}>
            Home
          </Button>
          <Button
            sx={{ color: "black" }}
            onClick={() => handleClick("/userguide")}
          >
            User Guide
          </Button>
          <Button
            sx={{ color: "black" }}
            onClick={() => handleClick("/contact")}
          >
            Contact
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavigationBar;
