import { Edge } from "react-flow-renderer";

import { InstructionWireData } from "../../types/InstructionWire";
import { EdgeTextDetails } from "./CustomEdge";

export const generateEdges = (
  isClearState: boolean,
  instructionWireState: InstructionWireData
): Edge<EdgeTextDetails>[] => {
  return [
    {
      id: "e-1",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "RegSrc-DecoderNode",
      target: "registerSecondInputMultiplexerNode",
      targetHandle: "top-RegisterSecondInputMultiplexerNode",
      data: {
        text:
          "RegSrc: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.registerSourceSegment)),
        xOffset: -10,
        yOffset: -13,
      },
    },
    {
      id: "e-2",
      type: "custom",
      source: "registerSecondInputMultiplexerNode",
      sourceHandle: "output-RegisterSecondInputMultiplexerNode",
      target: "registerFileNode",
      targetHandle: "A2-RegisterFileNode",
      data: {
        text:
          "A2: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.registerFileWires.registerEntryTwoSegment
              )),
        xOffset: -10,
        yOffset: -10,
      },
    },
    {
      id: "e-3",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "registerFileNode",
      targetHandle: "A1-RegisterFileNode",
      data: {
        text:
          "Rn: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.rnRegisterSegment)),
        xOffset: -25,
        yOffset: -10,
      },
    },
    {
      id: "e-4",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "registerSecondInputMultiplexerNode",
      targetHandle: "0-RegisterSecondInputMultiplexerNode",
      data: {
        text:
          "Rm: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.rmRegisterSegment)),
        xOffset: 1,
        yOffset: -3,
      },
    },

    {
      id: "e-6",
      type: "custom",
      source: "clockNode",
      sourceHandle: "output-ClockNode",
      target: "instructionMemoryNode",
      targetHandle: "A-InstructionMemoryNode",
      data: {
        text:
          "PC: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.currentPCValue)),
        xOffset: -4,
        yOffset: -25,
      },
    },
    {
      id: "e-7",
      type: "custom",
      source: "pcIncrementInputMultiplexerNode",
      sourceHandle: "output-PCIncrementInputMultiplexerNode",
      target: "clockNode",
      targetHandle: "input-ClockNode",
      data: {
        text:
          "PC+: " +
          (isClearState ? "" : Number(instructionWireState.updatedPCValue)),
        xOffset: 5,
        yOffset: -10,
      },
    },
    {
      id: "e-8",
      type: "custom",
      source: "clockNode",
      sourceHandle: "input-ClockNode",
      target: "pcAddFourNode",
      targetHandle: "PC-PCAddFourNode",
    },

    {
      id: "e-9",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "I-DecoderNode",
      data: {
        text:
          "I Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.iBitSegment)),
        xOffset: -23,
        yOffset: -30,
      },
    },
    {
      id: "e-10",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "L-DecoderNode",
      data: {
        text:
          "L Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.lBitSegment)),
        xOffset: -25,
        yOffset: -40,
      },
    },
    {
      id: "e-11",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "P-DecoderNode",
      data: {
        text:
          "P Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.pBitSegment)),
        xOffset: -26,
        yOffset: -50,
      },
    },
    {
      id: "e-12",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "U-DecoderNode",
      data: {
        text:
          "U Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.uBitSegment)),
        xOffset: -27,
        yOffset: -60,
      },
    },
    {
      id: "e-13",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "W-DecoderNode",
      data: {
        text:
          "W Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.wBitSegment)),
        xOffset: -28,
        yOffset: -70,
      },
    },
    {
      id: "e-14",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "S-DecoderNode",
      data: {
        text:
          "S Bit: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.sBitSegment)),
        xOffset: -26,
        yOffset: -80,
      },
    },
    {
      id: "e-15",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "decoderNode",
      targetHandle: "OP-DecoderNode",
      data: {
        text:
          "OP: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.opSegment)),
        xOffset: -21,
        yOffset: -90,
      },
    },

    {
      id: "e-16",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "RnRegWrite-DecoderNode",
      target: "registerFileNode",
      targetHandle: "WE1-RegisterFileNode",
      data: {
        text:
          "RnRegWrite: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.rnRegisterWriteSegment)),
        xOffset: -7,
        yOffset: -15,
      },
    },
    {
      id: "e-17",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "RegWrite-DecoderNode",
      target: "registerFileNode",
      targetHandle: "WE3-RegisterFileNode",
      data: {
        text:
          "RegWrite: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.registerWriteSegment)),
        xOffset: -34,
        yOffset: -23,
      },
    },
    {
      id: "e-18",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "IsPostIndex-DecoderNode",
      target: "targetAddressMultiplexerNode",
      targetHandle: "top-TargetAddressMultiplexerNode",
      data: {
        text:
          "IsPostIndex: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.decoderWires.postIndexInstructionSegment
              )),
        xOffset: -108,
        yOffset: -27,
      },
    },
    {
      id: "e-19",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "ALUSrc-DecoderNode",
      target: "registerSecondOutputMultiplexerNode",
      targetHandle: "top-RegisterSecondOutputMultiplexerNode",
      data: {
        text:
          "ALUSrc[0]: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.decoderWires.aluSourceIndexZeroSegment
              )),
        xOffset: 65,
        yOffset: -60,
      },
    },
    {
      id: "e-20",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "ALUSrc-DecoderNode",
      target: "registerFirstOutputMultiplexerNode",
      targetHandle: "top-RegisterFirstOutputMultiplexerNode",
      data: {
        text:
          "ALUSrc[1]: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.decoderWires.aluSourceIndexOneSegment
              )),
        xOffset: 20,
        yOffset: -37,
      },
    },
    {
      id: "e-21",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "ALUControl-DecoderNode",
      target: "aluNode",
      targetHandle: "ALUControl-ALUNode",
      data: {
        text:
          "ALUControl: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.aluControlSegment)),
        xOffset: -94,
        yOffset: -53,
      },
    },
    {
      id: "e-22",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "MemWrite-DecoderNode",
      target: "dataMemoryNode",
      targetHandle: "WE-DataMemoryNode",
      data: {
        text:
          "MemWrite: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.memoryWriteSegment)),
        xOffset: -173,
        yOffset: -70,
      },
    },
    {
      id: "e-23",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "MemToReg-DecoderNode",
      target: "resultMultiplexerNode",
      targetHandle: "top-ResultMultiplexerNode",
      data: {
        text:
          "MemToReg: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.decoderWires.memoryToRegisterSegment
              )),
        xOffset: -192,
        yOffset: -131,
      },
    },
    {
      id: "e-24",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "FlagWrite-DecoderNode",
      target: "statusNode",
      targetHandle: "En-StatusNode",
      data: {
        text:
          "FlagWrite: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.flagWriteSegment)),
        xOffset: -103,
        yOffset: -23,
      },
    },
    {
      id: "e-25",
      type: "custom",
      source: "decoderNode",
      sourceHandle: "PCS-DecoderNode",
      target: "conditionCheckNode",
      targetHandle: "PCS-ConditionCheckNode",
      data: {
        text:
          "PCS: " +
          (isClearState
            ? ""
            : Number(instructionWireState.decoderWires.pcsSegment)),
        xOffset: -70,
        yOffset: -15,
      },
    },

    {
      id: "e-26",
      type: "custom",
      source: "statusNode",
      sourceHandle: "N-StatusNode",
      target: "conditionCheckNode",
      targetHandle: "N-ConditionCheckNode",
      data: {
        text:
          "N Flag: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.conditionalWires.currentFlagsSegment
                  .negativeFlag
              )),
        xOffset: -15,
        yOffset: -10,
      },
    },
    {
      id: "e-27",
      type: "custom",
      source: "statusNode",
      sourceHandle: "C-StatusNode",
      target: "conditionCheckNode",
      targetHandle: "C-ConditionCheckNode",
      data: {
        text:
          "C Flag: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.conditionalWires.currentFlagsSegment
                  .carryFlag
              )),
        xOffset: -15,
        yOffset: -10,
      },
    },
    {
      id: "e-28",
      type: "custom",
      source: "statusNode",
      sourceHandle: "V-StatusNode",
      target: "conditionCheckNode",
      targetHandle: "V-ConditionCheckNode",
      data: {
        text:
          "V Flag: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.conditionalWires.currentFlagsSegment
                  .overflowFlag
              )),
        xOffset: -15,
        yOffset: -10,
      },
    },
    {
      id: "e-29",
      type: "custom",
      source: "statusNode",
      sourceHandle: "Z-StatusNode",
      target: "conditionCheckNode",
      targetHandle: "Z-ConditionCheckNode",
      data: {
        text:
          "Z Flag: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.conditionalWires.currentFlagsSegment
                  .zeroFlag
              )),
        xOffset: -15,
        yOffset: -10,
      },
    },
    {
      id: "e-30",
      type: "custom",
      source: "conditionCheckNode",
      sourceHandle: "PCSrc-ConditionCheckNode",
      target: "pcIncrementInputMultiplexerNode",
      targetHandle: "top-PCIncrementInputMultiplexerNode",
      data: {
        text:
          "PCSrc: " +
          (isClearState
            ? ""
            : Number(instructionWireState.conditionalWires.pcsrcSegment)),
        xOffset: -100,
        yOffset: -45,
      },
    },

    {
      id: "e-31",
      type: "custom",
      source: "aluNode",
      sourceHandle: "ALUFlags-ALUNode",
      target: "statusNode",
      targetHandle: "D-StatusNode",
      data: {
        text:
          "ALUFlags: " +
          (isClearState
            ? ""
            : "{ N: " +
              Number(
                instructionWireState.aluWires.aluFlagsSegment.negativeFlag
              ) +
              ", C: " +
              Number(instructionWireState.aluWires.aluFlagsSegment.carryFlag) +
              ", V: " +
              Number(
                instructionWireState.aluWires.aluFlagsSegment.overflowFlag
              ) +
              ", Z: " +
              Number(instructionWireState.aluWires.aluFlagsSegment.zeroFlag) +
              " }"),
        xOffset: 4,
        yOffset: -15,
      },
    },

    {
      id: "e-33",
      type: "custom",
      source: "registerFirstOutputMultiplexerNode",
      sourceHandle: "output-RegisterFirstOutputMultiplexerNode",
      target: "aluNode",
      targetHandle: "SrcA-ALUNode",
      data: {
        text: isClearState
          ? ""
          : Number(
              instructionWireState.aluWires.sourceAALUUnitSegment
            ).toString(),
        xOffset: -22,
        yOffset: -10,
      },
    },
    {
      id: "e-34",
      type: "custom",
      source: "registerFileNode",
      sourceHandle: "RD1-RegisterFileNode",
      target: "registerFirstOutputMultiplexerNode",
      targetHandle: "0-RegisterFirstOutputMultiplexerNode",
      data: {
        text: isClearState
          ? ""
          : Number(
              instructionWireState.registerFileWires.registerOutputOneSegment
            ).toString(),
        xOffset: -10,
        yOffset: -10,
      },
    },

    {
      id: "e-35",
      type: "custom",
      source: "registerFileNode",
      sourceHandle: "RD2-RegisterFileNode",
      target: "registerSecondOutputMultiplexerNode",
      targetHandle: "0-RegisterSecondOutputMultiplexerNode",
      data: {
        text: isClearState
          ? ""
          : Number(
              instructionWireState.registerFileWires.registerOutputTwoSegment
            ).toString(),
        xOffset: -20,
        yOffset: -10,
      },
    },
    {
      id: "e-36",
      type: "custom",
      source: "registerSecondOutputMultiplexerNode",
      sourceHandle: "output-RegisterSecondOutputMultiplexerNode",
      target: "aluNode",
      targetHandle: "SrcB-ALUNode",
      data: {
        text: isClearState
          ? ""
          : Number(
              instructionWireState.registerFileWires.registerOutputTwoSegment
            ).toString(),
        xOffset: -25,
        yOffset: 2,
      },
    },
    {
      id: "e-37",
      type: "custom",
      source: "aluNode",
      sourceHandle: "ALUResult-ALUNode",
      target: "targetAddressMultiplexerNode",
      targetHandle: "0-TargetAddressMultiplexerNode",
      data: {
        text: isClearState
          ? ""
          : Number(instructionWireState.aluWires.aluResultSegment).toString(),
        xOffset: -3,
        yOffset: 13,
      },
    },

    {
      id: "e-38",
      type: "custom",
      source: "registerFirstOutputMultiplexerNode",
      sourceHandle: "output-RegisterFirstOutputMultiplexerNode",
      target: "targetAddressMultiplexerNode",
      targetHandle: "1-TargetAddressMultiplexerNode",
    },

    {
      id: "e-39",
      type: "custom",
      source: "targetAddressMultiplexerNode",
      sourceHandle: "output-TargetAddressMultiplexerNode",
      target: "dataMemoryNode",
      targetHandle: "A-DataMemoryNode",
      data: {
        text: isClearState
          ? ""
          : Number(
              instructionWireState.dataMemoryWires.dataMemoryAddressTarget
            ).toString(),
        xOffset: -20,
        yOffset: 8,
      },
    },
    {
      id: "e-40",
      type: "custom",
      source: "dataMemoryNode",
      sourceHandle: "RD-DataMemoryNode",
      target: "resultMultiplexerNode",
      targetHandle: "1-ResultMultiplexerNode",
      data: {
        text:
          "ReadData: " +
          (isClearState
            ? ""
            : Number(
                instructionWireState.dataMemoryWires.readDataSegment
              ).toString()),
        xOffset: -12,
        yOffset: 10,
      },
    },

    {
      id: "ie-1",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "rdIntermediateNode",
    },
    {
      id: "ie-2",
      type: "custom",
      source: "rdIntermediateNode",
      target: "registerFileNode",
      targetHandle: "A3-RegisterFileNode",
      data: {
        text:
          "Rd: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.rdRegisterSegment)),
        xOffset: -38,
        yOffset: -10,
      },
    },
    {
      id: "ie-3",
      type: "custom",
      source: "rdIntermediateNode",
      target: "registerSecondInputMultiplexerNode",
      targetHandle: "1-RegisterSecondInputMultiplexerNode",
    },

    {
      id: "ie-4",
      type: "custom",
      source: "instructionMemoryNode",
      sourceHandle: "RD-InstructionMemoryNode",
      target: "immediateIntermediateNode",
    },
    {
      id: "ie-5",
      type: "custom",
      source: "immediateIntermediateNode",
      target: "secondImmediateIntermediateNode",
      data: {
        text:
          "Immediate (imm12): " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.immediateSegment)),
        xOffset: -90,
        yOffset: -10,
      },
    },
    {
      id: "ie-6",
      type: "custom",
      source: "secondImmediateIntermediateNode",
      target: "registerSecondOutputMultiplexerNode",
      targetHandle: "1-RegisterSecondOutputMultiplexerNode",
    },
    {
      id: "ie-7",
      type: "custom",
      source: "pcAddFourNode",
      sourceHandle: "PCPlus4-PCAddFourNode",
      target: "pcAddFourIntermediateNode",
      data: {
        text:
          "PCPlus4: " +
          (isClearState
            ? ""
            : Number(instructionWireState.machineCodeWires.currentPCValue + 4)),
        xOffset: -105,
        yOffset: -8,
      },
    },
    {
      id: "ie-8",
      type: "custom",
      source: "pcAddFourIntermediateNode",
      target: "registerFirstOutputMultiplexerNode",
      targetHandle: "1-RegisterFirstOutputMultiplexerNode",
    },
    {
      id: "ie-9",
      type: "custom",
      source: "pcAddFourNode",
      sourceHandle: "PCPlus4-PCAddFourNode",
      target: "secondPCAddFourIntermediateNode",
    },
    {
      id: "ie-10",
      type: "custom",
      source: "secondPCAddFourIntermediateNode",
      target: "thirdPCAddFourIntermediateNode",
    },
    {
      id: "ie-11",
      type: "custom",
      source: "thirdPCAddFourIntermediateNode",
      target: "pcIncrementInputMultiplexerNode",
      targetHandle: "0-PCIncrementInputMultiplexerNode",
    },
    {
      id: "ie-12",
      type: "custom",
      source: "registerFileNode",
      sourceHandle: "RD2-RegisterFileNode",
      target: "rdTwoIntermediateNode",
    },
    {
      id: "ie-13",
      type: "custom",
      source: "rdTwoIntermediateNode",
      target: "dataMemoryNode",
      targetHandle: "WD-DataMemoryNode",
      data: {
        text: "WriteData",
        xOffset: 0,
        yOffset: 5,
      },
    },
    {
      id: "ie-14",
      type: "custom",
      source: "targetAddressMultiplexerNode",
      sourceHandle: "output-TargetAddressMultiplexerNode",
      target: "aluResultAfterMultiplexerIntermediateNode",
    },
    {
      id: "ie-15",
      type: "custom",
      source: "aluResultAfterMultiplexerIntermediateNode",
      target: "resultMultiplexerNode",
      targetHandle: "0-ResultMultiplexerNode",
    },

    {
      id: "ie-16",
      type: "custom",
      source: "resultMultiplexerNode",
      sourceHandle: "output-ResultMultiplexerNode",
      target: "resultIntermediateNode",
    },
    {
      id: "ie-17",
      type: "custom",
      source: "resultIntermediateNode",
      target: "secondResultIntermediateNode",
      data: {
        text:
          "Result: " +
          (isClearState
            ? ""
            : Number(instructionWireState.dataMemoryWires.resultSegment)),
        xOffset: 0,
        yOffset: -10,
      },
    },
    {
      id: "ie-18",
      type: "custom",
      source: "secondResultIntermediateNode",
      target: "registerFileNode",
      targetHandle: "WD3-RegisterFileNode",
    },
    {
      id: "ie-19",
      type: "custom",
      source: "secondResultIntermediateNode",
      target: "thirdResultIntermediateNode",
    },
    {
      id: "ie-20",
      type: "custom",
      source: "thirdResultIntermediateNode",
      target: "pcIncrementInputMultiplexerNode",
      targetHandle: "1-PCIncrementInputMultiplexerNode",
    },

    {
      id: "ie-21",
      type: "custom",
      source: "aluNode",
      sourceHandle: "ALUResult-ALUNode",
      target: "aluResultIntermediateNode",
    },
    {
      id: "ie-22",
      type: "custom",
      source: "aluResultIntermediateNode",
      target: "secondALUResultIntermediateNode",
    },
    {
      id: "ie-23",
      type: "custom",
      source: "secondALUResultIntermediateNode",
      target: "registerFileNode",
      targetHandle: "WD1-RegisterFileNode",
    },
  ];
};
