import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import {
  CustomHandle,
  handleOffsetAtSide,
  handleOffsetDifference,
} from "./CustomHandle";
import GeneralNodeNoTable from "./GeneralNodeNoTable";

const nodeHeight: number = 100;
const nodeWidth: number = 20;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowFive = {
  top: handleOffsetAtSide + handleOffsetDifference * 4,
};

const ALUNode: React.FC = () => {
  return (
    <>
      <GeneralNodeNoTable name="ALU" gridStyle={gridStyle} />
      <CustomHandle
        id="SrcA-ALUNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"SrcA"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="SrcB-ALUNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"SrcB"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="ALUControl-ALUNode"
        type="target"
        position={Position.Top}
        toolTipLabel={"ALUControl"}
        handleStyle={{}}
      />
      <CustomHandle
        id="ALUFlags-ALUNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"ALUFlags"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="ALUResult-ALUNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"ALUResult"}
        handleStyle={{}}
      />
    </>
  );
};

export default ALUNode;
