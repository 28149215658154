import { Tooltip } from "@mui/material";
import React from "react";
import { Handle, HandleType, Position } from "react-flow-renderer";

const baseHandleStyle = {
  height: "5px",
  width: "5px",
};

interface CustomHandleProps {
  id: string;
  type: HandleType;
  position: Position;
  toolTipLabel: string;
  handleStyle: Object;
  isDisableTooltip?: boolean;
}

const handleOffsetAtSide: number = 20;

const handleOffsetDifference: number = 17;

const handleOffsetAtTop: number = 5;

const CustomHandle: React.FC<CustomHandleProps> = ({
  id,
  type,
  position,
  toolTipLabel,
  handleStyle,
  isDisableTooltip = false,
}: CustomHandleProps) => {
  return (
    <Tooltip title={toolTipLabel}>
      <Handle
        id={id}
        type={type}
        position={position}
        style={{ ...baseHandleStyle, ...handleStyle }}
        isConnectable={!isDisableTooltip}
      />
    </Tooltip>
  );
};

export {
  CustomHandle,
  handleOffsetDifference,
  handleOffsetAtSide,
  handleOffsetAtTop,
};
