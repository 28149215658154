import { useMediaQuery } from "@mui/material";
import { createContext, useContext } from "react";

interface ChildComponentsProp {
  children: React.ReactNode;
}

const MobileViewContext = createContext<boolean>(false);

const MobileViewContextHandler: React.FC<ChildComponentsProp> = ({
  children,
}: ChildComponentsProp) => {
  const isMobile: boolean = useMediaQuery("(max-width: 1000px)");
  return (
    <MobileViewContext.Provider value={isMobile}>
      {children}
    </MobileViewContext.Provider>
  );
};

const useMobileViewHandler = (): boolean => {
  return useContext<boolean>(MobileViewContext);
};

export { MobileViewContextHandler, useMobileViewHandler };
