import MultiplexerGeneralNode from "./MultiplexerGeneralNode";

const RegisterSecondOutputMultiplexerNode: React.FC = () => {
  return (
    <MultiplexerGeneralNode
      leftTopId="0-RegisterSecondOutputMultiplexerNode"
      leftBottomId="1-RegisterSecondOutputMultiplexerNode"
      topId="top-RegisterSecondOutputMultiplexerNode"
      rightId="output-RegisterSecondOutputMultiplexerNode"
      topInput="0"
      bottomInput="1"
    />
  );
};

export default RegisterSecondOutputMultiplexerNode;
