import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { useExecutionStateReponseHandler } from "../context/InstructionExecutionStateContextHandler";
import DecoderTable from "../tables/DecoderTable";
import {
  CustomHandle,
  handleOffsetAtSide,
  handleOffsetDifference,
} from "./CustomHandle";
import GeneralNode from "./GeneralNode";

const nodeHeight: number = 160;
const nodeWidth: number = 50;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const handleStyleRowOne = {
  top: handleOffsetAtSide,
};

const handleStyleRowTwo = {
  top: handleOffsetAtSide + handleOffsetDifference,
};

const handleStyleRowThree = {
  top: handleOffsetAtSide + handleOffsetDifference * 2,
};

const handleStyleRowFour = {
  top: handleOffsetAtSide + handleOffsetDifference * 3,
};

const handleStyleRowFive = {
  top: handleOffsetAtSide + handleOffsetDifference * 4,
};

const handleStyleRowSix = {
  top: handleOffsetAtSide + handleOffsetDifference * 5,
};

const handleStyleRowSeven = {
  top: handleOffsetAtSide + handleOffsetDifference * 6,
};

const handleStyleRowEight = {
  top: handleOffsetAtSide + handleOffsetDifference * 7,
};

const handleStyleRowNine = {
  top: handleOffsetAtSide + handleOffsetDifference * 8,
};

const DecoderNode: React.FC = () => {
  const { executionStateResponseData } = useExecutionStateReponseHandler();

  const table: React.ReactNode = (
    <DecoderTable
      isClearState={executionStateResponseData.clearState}
      machineWireState={
        executionStateResponseData.instructionWireDetails.machineCodeWires
      }
    />
  );
  return (
    <>
      <GeneralNode header="Decoder" gridStyle={gridStyle} table={table} />
      <CustomHandle
        id="OP-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"OP"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="S-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"S Bit"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="W-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"W Bit"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="U-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"U Bit"}
        handleStyle={handleStyleRowFour}
      />
      <CustomHandle
        id="P-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"P Bit"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="L-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"L Bit"}
        handleStyle={handleStyleRowSix}
      />
      <CustomHandle
        id="I-DecoderNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"I Bit"}
        handleStyle={handleStyleRowSeven}
      />
      <CustomHandle
        id="PCS-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"PCS"}
        handleStyle={handleStyleRowOne}
      />
      <CustomHandle
        id="FlagWrite-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"FlagWrite"}
        handleStyle={handleStyleRowTwo}
      />
      <CustomHandle
        id="MemToReg-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"MemToReg"}
        handleStyle={handleStyleRowThree}
      />
      <CustomHandle
        id="MemWrite-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"MemWrite"}
        handleStyle={handleStyleRowFour}
      />
      <CustomHandle
        id="ALUControl-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"ALUControl"}
        handleStyle={handleStyleRowFive}
      />
      <CustomHandle
        id="ALUSrc-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"ALUSrc"}
        handleStyle={handleStyleRowSix}
      />
      <CustomHandle
        id="IsPostIndex-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"IsPostIndex"}
        handleStyle={handleStyleRowSeven}
      />
      <CustomHandle
        id="RegWrite-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RegWrite"}
        handleStyle={handleStyleRowEight}
      />
      <CustomHandle
        id="RnRegWrite-DecoderNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"RnRegWrite"}
        handleStyle={handleStyleRowNine}
      />
      <CustomHandle
        id="RegSrc-DecoderNode"
        type="source"
        position={Position.Bottom}
        toolTipLabel={"RegSrc"}
        handleStyle={{}}
      />
    </>
  );
};

export default DecoderNode;
