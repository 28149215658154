import { SxProps } from "@mui/material";
import React from "react";
import { Position } from "react-flow-renderer";

import { CustomHandle } from "./CustomHandle";
import GeneralNodeNoTable from "./GeneralNodeNoTable";

const nodeHeight: number = 15;
const nodeWidth: number = 20;

const gridStyle: SxProps = {
  height: nodeHeight.toString() + "px",
  width: nodeWidth.toString() + "px",
};

const PCAddFourNode: React.FC = () => {
  return (
    <>
      <GeneralNodeNoTable name="+4" gridStyle={gridStyle} isRotate={false} />
      <CustomHandle
        id="PC-PCAddFourNode"
        type="target"
        position={Position.Left}
        toolTipLabel={"PC"}
        handleStyle={{}}
      />
      <CustomHandle
        id="PCPlus4-PCAddFourNode"
        type="source"
        position={Position.Right}
        toolTipLabel={"PCPlus4"}
        handleStyle={{}}
      />
    </>
  );
};

export default PCAddFourNode;
