import { Paper, SxProps, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  AddressToLabelData,
  AddressToValueData,
} from "../../types/AddressMappers";

const tableContainerStyle: SxProps = {
  border: "1px solid rgba(0, 0,0,0.2)",
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  maxHeight: "100px",
  maxWidth: "70px",
  "&::-webkit-scrollbar": {
    height: 5,
    width: 5,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#C1C1C1",
    borderRadius: 2,
  },
};

const tableHeaderStyle: SxProps = {
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "1px",
};

const tableTextStyle: SxProps = {
  fontSize: 5,
  fontColor: "white",
};

interface DataMemoryTableProps {
  dataMemoryDataList: AddressToValueData[];
  addressToLabelDataList: AddressToLabelData[];
}

const DataMemoryTable: React.FC<DataMemoryTableProps> = ({
  dataMemoryDataList,
  addressToLabelDataList,
}: DataMemoryTableProps) => {
  const writeLabelAtAddressIfPresent = (address: number): string => {
    let result: string = "-";
    addressToLabelDataList.forEach((addressToLabel: AddressToLabelData) => {
      if (address === addressToLabel.address) {
        result = addressToLabel.label;
      }
    });
    return result;
  };

  return (
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table
        stickyHeader
        sx={{ height: "max-content" }}
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Address</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Label</Typography>
            </TableCell>
            <TableCell sx={tableHeaderStyle} align="center">
              <Typography sx={tableTextStyle}>Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataMemoryDataList.map((data: AddressToValueData) => (
            <TableRow key={data.address}>
              <TableCell sx={{ padding: "0px 0px" }} align="center">
                <Typography sx={tableTextStyle}>0x{data.address.toString(16)}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px 0px",
                }}
                align="center"
              >
                <Typography sx={tableTextStyle}>
                  {writeLabelAtAddressIfPresent(data.address)}
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: "0px 1px" }} align="center">
                <Typography sx={tableTextStyle}>0x{data.value.toString(16)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataMemoryTable;
